import Card from "@mui/material/Card";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {TbFlame} from "react-icons/tb";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import {IoIosLogIn} from "react-icons/io";
import {
    AiOutlineBlock,
    AiOutlineMinusSquare,
    AiOutlinePlusSquare,
    AiTwotoneTag,
    AiOutlineSolution,
    AiOutlineDollar
} from "react-icons/ai";
import {GoBook} from "react-icons/go";
import Hidden from "@mui/material/Hidden";
import {ClosedWonFlowsIcon} from "../../components/Icons/ClosedWonFlowsIcon";
import {ForecastIcon} from "../../components/Icons/ForecastIcon";
import { PiPuzzlePiece } from "react-icons/pi";
import {PiCoin} from "react-icons/pi";
import {NodeEffectivenessIcon} from "../../components/Icons/NodeEffectivenessIcon";
import {MdOutlineTimer} from "react-icons/md";
import {FaHatWizard} from "react-icons/fa";
import {BiAnalyse} from "react-icons/bi";
import {DigestIcon} from "../../components/Icons/DigestIcon";
import {GtmStatsIcon} from "../../components/Icons/GtmStatsIcon";
import {BsTextParagraph, BsGlobeAmericas} from "react-icons/bs";


export default function MobileMarketingMenu() {


    const [showqflowPredictExpand, setShowqflowPredictExpand] = React.useState(false)
    const [showqflowExplainExpand, setShowqflowExplainExpand] = React.useState(false)

    const handleQflowPredictExpand = () => {
        setShowqflowPredictExpand(!showqflowPredictExpand)
    }

    const handleQflowExplainExpand = () => {
        setShowqflowExplainExpand(!showqflowExplainExpand)
    }

    return (
        <Card sx={{padding: "10px", width: "100%"}}>
            <Hidden mdUp>
                <MenuItem component="a"

                          href="/login">
                    <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                        <IoIosLogIn size="1.25em"/>
                    </ListItemIcon>
                    <ListItemText primary="Sign in"/>
                </MenuItem>
            </Hidden>
            <MenuItem disableRipple={true}
                      style={{borderTop: '1px dotted #000'}}
                      onClick={handleQflowPredictExpand}
            >
                <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                    <FaHatWizard/>
                </ListItemIcon>
                <ListItemText primary="QFlow Predict"
                              secondary="Predict revenue outcomes over multiple quarters"/>
                <ListItemIcon>{!showqflowPredictExpand ? <AiOutlinePlusSquare size="1.25em"/> :
                    <AiOutlineMinusSquare size="1.25em"/>}</ListItemIcon>
            </MenuItem>


            {showqflowPredictExpand &&
                <React.Fragment>
                    <MenuItem component="a"
                              href='/products/go-to-market-stats'>
                        <ListItemIcon style={{width: '2em', minWidth: '1.5em'}}>
                            <GtmStatsIcon size={'1em'}/>
                        </ListItemIcon>
                        <ListItemText primary="Go-to-Market Finance Metrics"
                                      secondary="Capture GTM stats for revenue waterfalls"/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span
                        style={{
                            textDecoration: "none",
                            whiteSpace: "nowrap",
                            borderRadius: "3px",
                            backgroundColor: "#4093D7",
                            color: "#FFF",
                            fontWeight: 400,
                            fontSize: "0.75em",
                            textTransform: "uppercase"
                        }}>&nbsp;&nbsp;New&nbsp;&nbsp;</span>
                    </MenuItem>

                    <MenuItem component="a"
                              href="/products/multi-quarter-projections">
                        <ListItemIcon style={{width: '2em', minWidth: '1.5em'}}>
                            <ForecastIcon size={'1em'}/>
                        </ListItemIcon>
                        <ListItemText primary="Multi-quarter Revenue Projections"
                                      secondary="Predict revenue using ML, sales forecasts, and GTM stats"/>
                    </MenuItem>
                    <MenuItem
                        component="a"
                        href="/products/forecasting"
                    >
                        <ListItemIcon style={{width: '2em', minWidth: '1.5em'}}>
                            {/*<Hidden mdUp>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Hidden>*/}
                            <ClosedWonFlowsIcon size={'1em'}/>
                        </ListItemIcon>
                        <ListItemText primary="In-quarter Sales Forecasting"
                                      secondary="Orchestrate or integrate forecast submissions"/>

                    </MenuItem>
                    <MenuItem component="a"
                              href="/products/machine-learning">
                        <ListItemIcon style={{width: '2em', minWidth: '1.5em'}}>
                            {/*<Hidden mdUp>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Hidden>*/}
                            <BsTextParagraph size="1em"/>
                        </ListItemIcon>
                        <ListItemText primary="QPilot"
                                      secondary="Your Generative AI assistant for GTM analytics"/>&nbsp;&nbsp;&nbsp;&nbsp;
                        <span
                            style={{
                                textDecoration: "none",
                                whiteSpace: "nowrap",
                                borderRadius: "3px",
                                backgroundColor: "#4093D7",
                                color: "#FFF",
                                fontWeight: 400,
                                fontSize: "0.75em",
                                textTransform: "uppercase"
                            }}>&nbsp;&nbsp;New&nbsp;&nbsp;</span>
                    </MenuItem>
                </React.Fragment>
            }


            <MenuItem disableRipple={true}
                      style={{borderTop: '1px dotted #000'}}
                      onClick={handleQflowExplainExpand}>
                <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                    <BiAnalyse size="1em"/>
                </ListItemIcon>
                <ListItemText primary="QFlow Explain"
                              secondary="Identify, inspect, and monitor business drivers"/>
                <ListItemIcon>{!showqflowExplainExpand ? <AiOutlinePlusSquare size="1.25em"/> :
                    <AiOutlineMinusSquare size="1.25em"/>}</ListItemIcon>
            </MenuItem>


            {showqflowExplainExpand &&
                <React.Fragment>
                    <MenuItem component="a"
                              href="/products/business-digest">
                        <ListItemIcon style={{width: '2em', minWidth: '1.5em'}}>
                            {/*<Hidden mdUp>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Hidden>*/}
                            <DigestIcon size='1em'/>
                        </ListItemIcon>
                        <ListItemText primary="Business Digest"
                                      secondary="Highlight material changes and insights in plain text"/>
                    </MenuItem>

                    <MenuItem component="a"
                              href="/products/account-based-attribution">
                        <ListItemIcon style={{width: '2em', minWidth: '1.5em'}}>
                            {/*<Hidden mdUp>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Hidden>*/}
                            <ClosedWonFlowsIcon size='1em'/>
                        </ListItemIcon>
                        <ListItemText primary="Multi-Touch Attribution"
                                      secondary="Unbiased attribution across marketing and sales"/>
                    </MenuItem>

                    <MenuItem component="a" href="/products/campaign-measurement" disableRipple={true}>
                        <ListItemIcon style={{width: '2em', minWidth: '1.5em'}}>
                            <NodeEffectivenessIcon size="1em"/>
                        </ListItemIcon>
                        <ListItemText primary="Campaign Effectiveness"
                                      secondary="Prioritize programs with higher ARR:spend ratios"/>
                    </MenuItem>

                    <MenuItem component="a" href="/products/inbound-lead-response-measurement"
                              disableRipple={true}>
                        <ListItemIcon style={{width: '2em', minWidth: '1.5em'}}>
                            <MdOutlineTimer size="1em"/>
                        </ListItemIcon>
                        <ListItemText primary="Inbound Response Measurement"
                                      secondary="Maintain SLAs for priority inbound lead follow up"/>
                    </MenuItem>


                    <MenuItem component="a" href="/products/sales-activity-analytics" disableRipple={true}>
                        <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                            <TbFlame size="1em"/>
                        </ListItemIcon>
                        <ListItemText primary="Sales Activity Analytics"
                                      secondary="Measure outbound activity and productivity"/>
                    </MenuItem>
                    <MenuItem component="a" href="/products/sales-pipeline-analytics" disableRipple={true}>
                        <ListItemIcon style={{width: '2em', minWidth: '2em'}}>

                            <PiCoin size="1em"/>
                        </ListItemIcon>
                        <ListItemText primary="Pipeline Forensics"
                                      secondary="Gain the power to explain what changed"/>
                    </MenuItem>
                </React.Fragment>
            }

            <MenuItem component="a"
                      style={{borderTop: '1px dotted #000'}}
                      href="/products/integrations">
                <ListItemIcon style={{width: '2em', minWidth: '1.5em'}}>
                    {/*<IoExtensionPuzzle />*/}
                    <PiPuzzlePiece size="1em"/>

                </ListItemIcon>
                <ListItemText primary="QFlow Connect"
                              secondary="Unify sales and marketing data chronologically"
                />
            </MenuItem>

            <Hidden mdUp> <MenuItem component="a"
                                    style={{borderTop: '1px dotted #000'}}
                                    href="/solutions/finance">
                <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                    <AiOutlineDollar size={"1em"}/>
                </ListItemIcon>
                <ListItemText primary="For Finance"/>
            </MenuItem>
            </Hidden>

            <Hidden mdUp> <MenuItem component="a"
                                    style={{borderTop: '1px dotted #000'}}
                                    href="/solutions/revenue-operations">
                <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                    <AiOutlineSolution size={"1em"}/>
                </ListItemIcon>
                <ListItemText primary="For RevOps"/>
            </MenuItem>
            </Hidden>


            <Hidden mdUp> <MenuItem component="a"
                                    style={{borderTop: '1px dotted #000'}}
                                    href="/pricing">
                <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                    <AiTwotoneTag size={"1em"}/>
                </ListItemIcon>
                <ListItemText primary="Pricing"/>
            </MenuItem>
            </Hidden>

            <Hidden mdUp> <MenuItem component="a"
                                    style={{borderTop: '1px dotted #000'}}
                                    href="/partners">
                <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                    <AiOutlineBlock size={"1em"}/>
                </ListItemIcon>
                <ListItemText primary="Partners"/>
            </MenuItem>
            </Hidden>
            <Hidden mdUp> <MenuItem component="a"
                                    style={{borderTop: '1px dotted #000'}}
                                    href="/about">
                <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                    <BsGlobeAmericas size={"1em"}/>
                </ListItemIcon>
                <ListItemText primary="About"/>
            </MenuItem>
            </Hidden>

            <Hidden mdUp> <MenuItem component="a"
                                    style={{borderTop: '1px dotted #000'}}
                                    href="https://blog.qflow.ai">
                <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                    <GoBook size={"1em"}/>
                </ListItemIcon>
                <ListItemText primary="Blog"/>
            </MenuItem>
            </Hidden>
        </Card>
    )
}