const RolePermissions =
    {
        "admin_user": {
            "access-denied": {hasAccess: true},
            "admin": {hasAccess: true},
            "billing": {hasAccess: true},
            "company": {hasAccess: true},
            "faq": {hasAccess: true},
            "manage-users":  {hasAccess: true},
            "settings": {hasAccess: true},
            'board-stats': {hasAccess: true},
            'business-digest': {hasAccess: true},
            'config': {hasAccess: true},
            'customer-invoices': {hasAccess: true},
            'department-spend': {hasAccess: true},
            'arr-schedule': {hasAccess: true},
            'dealexplorer': {hasAccess: true},
            'forecast': {hasAccess: true},
            'gtm-stats': {hasAccess: true},
            'inbound-follow-up': {hasAccess: true},
            'opp-creation': {hasAccess: true},
            'pclose': {hasAccess: true},
            'pconvert': {hasAccess: true},
            'pipeline-forensics': {hasAccess: true},
            'pipelineflows': {hasAccess: true},
            'program-effectiveness': {hasAccess: true},
            'programeffectiveness': {hasAccess: true},
            'sales-effort': {hasAccess: true},
            'sales-ramp': {hasAccess: true},
            'scenarios': {hasAccess: true},
            'win-rate': {hasAccess: true},
            'winflows': {hasAccess: true},
        },

        "general_user": {
            "access-denied": {hasAccess: true},
            "admin": {hasAccess: false},
            "billing": {hasAccess: false},
            "company": {hasAccess: false},
            "faq": {hasAccess: true},
            "manage-users":  {hasAccess: false},
            "settings": {hasAccess: true},
            'board-stats': {hasAccess: true},
            'business-digest': {hasAccess: true},
            'config': {hasAccess: true},
            'customer-invoices': {hasAccess: true},
            'department-spend': {hasAccess: false},
            'arr-schedule': {hasAccess: false},
            'dealexplorer': {hasAccess: true},
            'forecast': {hasAccess: true},
            'gtm-stats': {hasAccess: true},
            'inbound-follow-up': {hasAccess: true},
            'opp-creation': {hasAccess: true},
            'pclose': {hasAccess: true},
            'pconvert': {hasAccess: true},
            'pipeline-forensics': {hasAccess: true},
            'pipelineflows': {hasAccess: true},
            'program-effectiveness': {hasAccess: true},
            'programeffectiveness': {hasAccess: true},
            'sales-effort': {hasAccess: true},
            'sales-ramp': {hasAccess: true},
            'scenarios': {hasAccess: true},
            'win-rate': {hasAccess: true},
            'winflows': {hasAccess: true},
        },
        "sales_user": {
            "access-denied": {hasAccess: true},
            "admin": {hasAccess: false},
            "billing": {hasAccess: false},
            "company": {hasAccess: false},
            "faq": {hasAccess: true},
            "manage-users":  {hasAccess: false},
            "settings": {hasAccess: true},
            'board-stats': {hasAccess: false},
            'business-digest': {hasAccess: false},
            'config': {hasAccess: false},
            'customer-invoices': {hasAccess: false},
            'department-spend': {hasAccess: false},
            'arr-schedule': {hasAccess: false},
            'dealexplorer': {hasAccess: false},
            'forecast': {hasAccess: true},
            'gtm-stats': {hasAccess: false},
            'inbound-follow-up': {hasAccess: false},
            'opp-creation': {hasAccess: false},
            'pclose': {hasAccess: false},
            'pconvert': {hasAccess: false},
            'pipeline-forensics': {hasAccess: false},
            'pipelineflows': {hasAccess: false},
            'program-effectiveness': {hasAccess: false},
            'programeffectiveness': {hasAccess: false},
            'sales-effort': {hasAccess: false},
            'sales-ramp': {hasAccess: false},
            'scenarios': {hasAccess: false},
            'win-rate': {hasAccess: false},
            'winflows': {hasAccess: false},

        },
        "sales_ops_user": {
            "access-denied": {hasAccess: true},
            "admin": {hasAccess: true},
            "billing": {hasAccess: false},
            "company": {hasAccess: true},
            "faq": {hasAccess: true},
            "manage-users":  {hasAccess: false},
            "settings": {hasAccess: true},
            'board-stats': {hasAccess: false},
            'business-digest': {hasAccess: true},
            'config': {hasAccess: true},
            'customer-invoices': {hasAccess: true},
            'department-spend': {hasAccess: false},
            'arr-schedule': {hasAccess: true},
            'dealexplorer': {hasAccess: true},
            'forecast': {hasAccess: true},
            'gtm-stats': {hasAccess: true},
            'inbound-follow-up': {hasAccess: true},
            'opp-creation': {hasAccess: true},
            'pclose': {hasAccess: true},
            'pconvert': {hasAccess: true},
            'pipeline-forensics': {hasAccess: true},
            'pipelineflows': {hasAccess: true},
            'program-effectiveness': {hasAccess: true},
            'programeffectiveness': {hasAccess: true},
            'sales-effort': {hasAccess: true},
            'sales-ramp': {hasAccess: true},
            'scenarios': {hasAccess: true},
            'win-rate': {hasAccess: true},
            'winflows': {hasAccess: true},

        },
        "marketing_user": {
            "access-denied": {hasAccess: true},
            "admin": {hasAccess: false},
            "billing": {hasAccess: false},
            "company": {hasAccess: false},
            "faq": {hasAccess: false},
            "manage-users":  {hasAccess: false},
            "settings": {hasAccess: true},
            'board-stats': {hasAccess: false},
            'business-digest': {hasAccess: true},
            'config': {hasAccess: true},
            'customer-invoices': {hasAccess: false},
            'department-spend': {hasAccess: false},
            'arr-schedule': {hasAccess: false},
            'dealexplorer': {hasAccess: true},
            'forecast': {hasAccess: true},
            'gtm-stats': {hasAccess: true},
            'inbound-follow-up': {hasAccess: true},
            'opp-creation': {hasAccess: true},
            'pclose': {hasAccess: true},
            'pconvert': {hasAccess: true},
            'pipeline-forensics': {hasAccess: true},
            'pipelineflows': {hasAccess: true},
            'program-effectiveness': {hasAccess: true},
            'programeffectiveness': {hasAccess: true},
            'sales-effort': {hasAccess: true},
            'sales-ramp': {hasAccess: true},
            'scenarios': {hasAccess: false},
            'win-rate': {hasAccess: true},
            'winflows': {hasAccess: true},

        },
        "marketing_ops_user": {
            "access-denied": {hasAccess: true},
            "admin": {hasAccess: true},
            "billing": {hasAccess: false},
            "company": {hasAccess: true},
            "faq": {hasAccess: true},
            "manage-users":  {hasAccess: false},
            "settings": {hasAccess: true},
            'board-stats': {hasAccess: false},
            'business-digest': {hasAccess: true},
            'config': {hasAccess: true},
            'customer-invoices': {hasAccess: false},
            'department-spend': {hasAccess: false},
            'arr-schedule': {hasAccess: false},
            'dealexplorer': {hasAccess: true},
            'forecast': {hasAccess: true},
            'gtm-stats': {hasAccess: true},
            'inbound-follow-up': {hasAccess: true},
            'opp-creation': {hasAccess: true},
            'pclose': {hasAccess: true},
            'pconvert': {hasAccess: true},
            'pipeline-forensics': {hasAccess: true},
            'pipelineflows': {hasAccess: true},
            'program-effectiveness': {hasAccess: true},
            'programeffectiveness': {hasAccess: true},
            'sales-effort': {hasAccess: true},
            'sales-ramp': {hasAccess: true},
            'scenarios': {hasAccess: true},
            'win-rate': {hasAccess: true},
            'winflows': {hasAccess: true},

        },
        "finance_user": {
            "access-denied": {hasAccess: true},
            "admin": {hasAccess: true},
            "billing": {hasAccess: true},
            "company": {hasAccess: false},
            "faq": {hasAccess: true},
            "manage-users":  {hasAccess: false},
            "settings": {hasAccess: true},
            'board-stats': {hasAccess: true},
            'business-digest': {hasAccess: true},
            'config': {hasAccess: true},
            'customer-invoices': {hasAccess: true},
            'department-spend': {hasAccess: true},
            'arr-schedule': {hasAccess: true},
            'dealexplorer': {hasAccess: true},
            'forecast': {hasAccess: true},
            'gtm-stats': {hasAccess: true},
            'inbound-follow-up': {hasAccess: true},
            'opp-creation': {hasAccess: true},
            'pclose': {hasAccess: true},
            'pconvert': {hasAccess: true},
            'pipeline-forensics': {hasAccess: true},
            'pipelineflows': {hasAccess: true},
            'program-effectiveness': {hasAccess: true},
            'programeffectiveness': {hasAccess: true},
            'sales-effort': {hasAccess: true},
            'sales-ramp': {hasAccess: true},
            'scenarios': {hasAccess: true},
            'win-rate': {hasAccess: true},
            'winflows': {hasAccess: true},

        },
    };

export default RolePermissions;