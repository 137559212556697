import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from 'material-ui-popup-state/HoverMenu'
import MenuItem from '@mui/material/MenuItem'
import PopupState, {
    bindHover,
    bindMenu
} from 'material-ui-popup-state'
import Hidden from "@mui/material/Hidden";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {IoIosLogIn} from "react-icons/io";
import ListItemText from "@mui/material/ListItemText";
import {FaProductHunt} from "react-icons/fa";
import { LiaHatWizardSolid } from "react-icons/lia";

import {BiAnalyse} from "react-icons/bi";
import {MdKeyboardArrowRight, MdOutlineArrowRight, MdOutlineArrowDropDown} from "react-icons/md";
import {ClosedWonFlowsIcon} from "../../components/Icons/ClosedWonFlowsIcon";
import {NodeEffectivenessIcon} from "../../components/Icons/NodeEffectivenessIcon";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import {GoBook} from "react-icons/go";
import {GrInspect} from "react-icons/gr";
import {ForecastIcon} from "../../components/Icons/ForecastIcon";
import NestedProductMarketingMenuContainer from './NestedProductMarketingMenuContainer';
import {DigestIcon} from "../../components/Icons/DigestIcon";
import {BsTextParagraph} from "react-icons/bs";
import {GtmStatsIcon} from "../../components/Icons/GtmStatsIcon";
import {styled} from "@mui/material/styles";
import {SalesRampIcon} from "../../components/Icons/SalesRampIcon";
import {ActivityHeatmapIcon} from "../../components/Icons/ActivityHeatmapIcon";
import {InboundFollowupIcon} from "../../components/Icons/InboundFollowupIcon";
import {PipelineFlowsIcon} from "../../components/Icons/PipelineFlowsIcon";


const ProductMarketingMenu = () => {


    const ProductMarketingMenuItem = styled(MenuItem)(({theme}) => ({
            backgroundColor: "#FFFFFF",
            // backgroundColor: useLightTheme ? "#FFFFFF" : "#272931",
            padding: "0px",
            textDecoration: "none",
            '&.MuiListItemIcon': {
                minWidth: "10px",
            },
            '&:hover': {
                // backgroundColor: "#2C649E",
                opacity: 0.84,
            },
            '&visited': {
                textDecoration: "none",
                color: "#272931",
            },
            '&link': {
                textDecoration: "none",
                color: "#272931",
            },
            '&:focus': {
                // backgroundColor: "#2C649E",
            },
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.primary.main,
                minWidth: "30px",
                padding: "10px",
                fontWeight: 600,
                fill: theme.palette.primary.main,

                "&:focus": {
                    // backgroundColor: theme.palette.primary.main
                    // backgroundColor: "#2C649E",
                },
                '&:hover': {
                    // backgroundColor: "#2C649E",
                    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                        color: theme.palette.common.white,
                        backgroundColor: "#393A43",
                    }
                }
            }
        }
    ))

    const ProductMarketingMenuItemNested = styled(MenuItem)(({theme}) => ({
        minWidth: '425px',
        '&.MuiListItemIcon': {
            minWidth: "10px",
        },
        '&:active': {
            backgroundColor: theme.palette.grey[100],
        },
        '&:hover': {
            backgroundColor: theme.palette.grey[100],
        },
        '&:focus': {
            backgroundColor: theme.palette.grey[100],
        },
        '&:selected': {
            backgroundColor: theme.palette.grey[100],
        },
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: theme.palette.primary.main,
            backgroundColor: "transparent",
            // minWidth: "30px",
            // padding: "10px",
            fontWeight: 600,
            '&:active': {
                backgroundColor: theme.palette.grey[100],
            },
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
            },
            '&:focus': {
                backgroundColor: theme.palette.grey[100],
            },
            '&:selected': {
                backgroundColor: theme.palette.grey[100],
            },
            // '&:hover': {
            //     backgroundColor: theme.palette["grey"]["100"]
            // }
            '& .MuiButtonBase-root': {
                backgroundColor: theme.palette.grey[100],
                '&:active': {
                    backgroundColor: theme.palette.grey[100],
                },
                '&:selected': {
                    backgroundColor: theme.palette.grey[100],
                },
                '&:hover': {
                    backgroundColor: theme.palette.grey[100],
                },
                '&:focus': {
                    backgroundColor: theme.palette.grey[100],
                }
            }
        }
    }))

    return (
        <PopupState variant="popover" popupId="demoMenu">
            {popupState => (
                <React.Fragment>
                    <Button sx={{
                        cursor: "pointer",
                        fontWeight: "bold"
                    }}  {...bindHover(popupState)} endIcon={<MdOutlineArrowDropDown/>}>
                        Product
                    </Button>
                    <Menu
                    {...bindMenu(popupState)}
                          sx={{
                              list: {
                                  padding: "0px"
                              }
                          }}>
                        {/*<Card style={{padding: "10px"}}>*/}


                            <NestedProductMarketingMenuContainer
                                rightIcon={null}
                                // ContainerProps={{style: {backgroundColor: '#272931'}}}
                                // onMouseEnter={handleHover}
                                // onClick={handleHover}
                                parentMenuOpen={true}
                                label={<ProductMarketingMenuItemNested disableRipple={true}>
                                    <ListItemIcon style={{width: '2em', minWidth: '2em', marginLeft: "-3px", paddingRight: "3px"}}>
                                        <LiaHatWizardSolid size={"1.2em"}/>
                                    </ListItemIcon>
                                    <ListItemText primary="QFlow Predict"
                                                  secondary="Predict revenue outcomes over multiple quarters"/>
                                    <ListItemIcon><MdOutlineArrowRight size="1.5em"/></ListItemIcon>
                                </ProductMarketingMenuItemNested>}>

                                <ProductMarketingMenuItemNested component={'a'} href={'/products/go-to-market-stats'}>
                                    <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                                        <Hidden mdUp>
                                            <LiaHatWizardSolid size={"1.2em"}/>

                                        </Hidden>
                                        <GtmStatsIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Go-to-Market Stats"
                                                  secondary="Capture core GTM stats for revenue waterfalls and reporting packages"/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;<span
                                    style={{
                                        textDecoration: "none",
                                        whiteSpace: "nowrap",
                                        borderRadius: "3px",
                                        backgroundColor: "#4093D7",
                                        color: "#FFF",
                                        fontWeight: 400,
                                        fontSize: "0.75em",
                                        textTransform: "uppercase"
                                    }}>&nbsp;&nbsp;New&nbsp;&nbsp;</span>
                                </ProductMarketingMenuItemNested>


                                <ProductMarketingMenuItemNested component={'a'}
                                                                href={'/products/multi-quarter-projections'}>
                                    <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                                        <Hidden mdUp>
                                            <LiaHatWizardSolid/>

                                        </Hidden>
                                        <ForecastIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Multi-quarter Revenue Projections"
                                                  secondary="Create revenue predictions using ML, sales forecasts, and GTM stats"/>
                                </ProductMarketingMenuItemNested>


                                <ProductMarketingMenuItemNested component="a" href="/products/forecasting"
                                                                disableRipple={true}>
                                    <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                                        <Hidden mdUp>
                                            <GrInspect/>
                                        </Hidden>
                                        <GrInspect/>
                                    </ListItemIcon>
                                    <ListItemText primary="In-quarter Sales Forecasting"
                                                  secondary="Orchestrate or integrate forecast submissions"/>
                                </ProductMarketingMenuItemNested>
                                <ProductMarketingMenuItemNested component="a" href="/products/machine-learning"
                                                                disableRipple={true}>
                                    <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                                        <Hidden mdUp>
                                            <BsTextParagraph size="1em"/>
                                        </Hidden>
                                        <BsTextParagraph size="1em"/>
                                    </ListItemIcon>
                                    <ListItemText primary="QPilot"
                                                  secondary="Your Generative AI assistant for all things GTM analytics"/>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span
                                        style={{
                                            textDecoration: "none",
                                            whiteSpace: "nowrap",
                                            borderRadius: "3px",
                                            backgroundColor: "#4093D7",
                                            color: "#FFF",
                                            fontWeight: 400,
                                            fontSize: "0.75em",
                                            textTransform: "uppercase"
                                        }}>&nbsp;&nbsp;New&nbsp;&nbsp;</span>
                                </ProductMarketingMenuItemNested>
                            </NestedProductMarketingMenuContainer>


                            <NestedProductMarketingMenuContainer
                                rightIcon={null}
                                parentMenuOpen={true}
                                label={<ProductMarketingMenuItemNested disableRipple={true}>
                                    <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                                        <BiAnalyse size="1em"/>
                                    </ListItemIcon>
                                    <ListItemText primary="QFlow Explain"
                                                  secondary="Identify, inspect, and monitor business drivers"/>
                                    <ListItemIcon><MdOutlineArrowRight size="1.5em"/></ListItemIcon>
                                </ProductMarketingMenuItemNested>}>

                                <ProductMarketingMenuItemNested component="a" href="/products/business-digest"
                                                                disableRipple={true}>
                                    <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                                        <Hidden mdUp><MdKeyboardArrowRight/></Hidden>
                                        <DigestIcon size='1em'/>
                                    </ListItemIcon>
                                    <ListItemText primary="Business Digest"
                                                  secondary="Highlight material changes and insights in plain text"/>
                                </ProductMarketingMenuItemNested>

                                  <ProductMarketingMenuItemNested component="a" href="/products/sales-pipeline-analytics"
                                                                disableRipple={true}>
                                    <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                                        <Hidden mdUp><MdKeyboardArrowRight/></Hidden>
                                        <PipelineFlowsIcon
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Pipeline Forensics"
                                                  secondary="Gain the power to explain what changed"/>
                                </ProductMarketingMenuItemNested>

                                <ProductMarketingMenuItemNested component="a" href="/products/account-based-attribution"
                                                                disableRipple={true}>
                                    <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                                        <Hidden mdUp><MdKeyboardArrowRight/></Hidden>
                                        <ClosedWonFlowsIcon size='1em'/>
                                    </ListItemIcon>
                                    <ListItemText primary="Multi-Touch Attribution"
                                                  secondary="Unbiased attribution across marketing and sales"/>
                                </ProductMarketingMenuItemNested>

                                <ProductMarketingMenuItemNested component="a" href="/products/campaign-measurement"
                                                                disableRipple={true}>
                                    <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                                        <Hidden mdUp><MdKeyboardArrowRight/></Hidden>
                                        <NodeEffectivenessIcon size="1.2em"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Campaign Effectiveness"
                                                  secondary="Prioritize programs with higher ARR:spend ratios"/>
                                </ProductMarketingMenuItemNested>

                                <ProductMarketingMenuItemNested component="a"
                                                                href="/products/inbound-lead-response-measurement"
                                                                disableRipple={true}>
                                    <ListItemIcon style={{width: '2em', minWidth: '1.5em'}}>
                                        <Hidden mdUp><MdKeyboardArrowRight/></Hidden>
                                        <InboundFollowupIcon
                                            />
                                    </ListItemIcon>
                                    <ListItemText primary="Response Measurement"
                                                  secondary="Maintain SLAs for CRM events that require priority follow up"/>
                                </ProductMarketingMenuItemNested>

                                <ProductMarketingMenuItemNested component="a" href="/products/sales-activity-analytics"
                                                                disableRipple={true}>
                                    <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                                        <Hidden mdUp><MdKeyboardArrowRight/></Hidden>
                                        <ActivityHeatmapIcon
                                            />
                                    </ListItemIcon>
                                    <ListItemText primary="Sales Activity Analytics"
                                                  secondary="Measure outbound activity and productivity"/>
                                </ProductMarketingMenuItemNested>

                                <ProductMarketingMenuItemNested component="a" href="/products/sales-ramp"
                                                                disableRipple={true}>
                                    <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                                        <Hidden mdUp><MdKeyboardArrowRight/></Hidden>
                                        <SalesRampIcon/>
                                        {/*<FaRegPaperPlane size="1em"/>*/}
                                    </ListItemIcon>
                                    <ListItemText primary="Sales Ramp"
                                                  secondary="Normalize sales ramp measurement"/>

                                </ProductMarketingMenuItemNested>
                            </NestedProductMarketingMenuContainer>

                            <NestedProductMarketingMenuContainer
                                rightIcon={null}
                                // ContainerProps={{style: {backgroundColor: '#272931'}}}
                                // onMouseEnter={handleHover}
                                // onClick={handleHover}
                                parentMenuOpen={true}
                                label={<ProductMarketingMenuItemNested disableRipple={true} component={'a'}
                                                                       href={'/products/integrations'}>
                                    <ListItemIcon style={{width: '2em', minWidth: '2em'}}>
                                        <IoExtensionPuzzleOutline size="1em"/>
                                    </ListItemIcon>
                                    <ListItemText primary="QFlow Connect"
                                                  secondary="Unify and blend GTM data, with or without a data stack"/>
                                    <ListItemIcon/>
                                </ProductMarketingMenuItemNested>}>
                            </NestedProductMarketingMenuContainer>


                            <Hidden mdUp>
                                <ProductMarketingMenuItem onClick={popupState.close}>
                                    <ListItem style={{padding: '0px 0px 0px 0px'}} component="a"
                                              href="/login">
                                        <ListItemIcon>
                                            <IoIosLogIn color="#272931" size="1.65em"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Login"/>
                                    </ListItem>
                                </ProductMarketingMenuItem>

                                <ProductMarketingMenuItem onClick={popupState.close}>
                                    <ListItem style={{
                                        padding: '0px 0px 0px 0px',
                                        // borderBottom: "1px dotted", borderBottomColor: "#6f7782"
                                        // backgroundColor: "#393A44",
                                    }} component="a"
                                              href="/products/integrations">
                                        <ListItemIcon>
                                            <FaProductHunt color="#272931" size="1.65em"/>
                                        </ListItemIcon>

                                        {/*<span style={{*/}
                                        {/*    padding: "5px", textAlign: "center", fontSize: "1em", textDecoration: "none",*/}
                                        {/*}}>*/}
                                        <ListItemText primary="Products"/>
                                        {/*</span>*/}
                                    </ListItem>
                                </ProductMarketingMenuItem>
                            </Hidden>


                            {/*<ProductMarketingMenuItem*/}
                            {/*    onClick={popupState.close}*/}

                            {/*>*/}
                            {/*    <ListItem style={{padding: '0px 0px 0px 0px'}} component="a"*/}
                            {/*              href="/products/account-based-attribution">*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <Hidden mdUp><MdKeyboardArrowRight color="#272931" size="1.65em"/></Hidden>*/}
                            {/*            /!*<PipelineFlowsIcon color="secondary"/>*!/*/}
                            {/*            <ClosedWonFlowsIcon size="1.65em" color="#272931"/>*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary="Multi-Touch Attribution"/>*/}
                            {/*    </ListItem>*/}
                            {/*</ProductMarketingMenuItem>*/}
                            {/*<Divider variant="middle" component="li"/>*/}
                            {/*<ProductMarketingMenuItem onClick={popupState.close}>*/}
                            {/*    <ListItem style={{padding: '0px 0px 0px 0px'}} component="a"*/}
                            {/*              href="/products/campaign-measurement">*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <Hidden mdUp><MdKeyboardArrowRight color="#272931" size="1.65em"/></Hidden>*/}
                            {/*            <NodeEffectivenessIcon size="1.65em" color="#272931"/>*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary="Campaign Effectiveness"/>*/}
                            {/*    </ListItem>*/}
                            {/*</ProductMarketingMenuItem>*/}
                            {/*<Divider variant="middle" component="li"/>*/}
                            {/*<ProductMarketingMenuItem onClick={popupState.close}>*/}
                            {/*    <ListItem style={{padding: '0px 0px 0px 0px'}} component="a"*/}
                            {/*              href="/products/inbound-lead-response-measurement">*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <Hidden mdUp><MdKeyboardArrowRight color="#272931" size="1.65em"/></Hidden>*/}
                            {/*            <div style={{fontSize: "1.65em"}}><TimerIcon fontSize="inherit" color="inherit"/>*/}
                            {/*            </div>*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary="Inbound Response Measurement"/>*/}
                            {/*        /!*&nbsp;&nbsp;&nbsp;&nbsp;<span*!/*/}
                            {/*        /!*style={{*!/*/}
                            {/*        /!*    textDecoration: "none",*!/*/}
                            {/*        /!*    whiteSpace: "nowrap",*!/*/}
                            {/*        /!*    borderRadius: "3px",*!/*/}
                            {/*        /!*    backgroundColor: "#4093D7",*!/*/}
                            {/*        /!*    color: "#FFF",*!/*/}
                            {/*        /!*    fontWeight: 400,*!/*/}
                            {/*        /!*    fontSize: "0.75em",*!/*/}
                            {/*        /!*    textTransform: "uppercase"*!/*/}
                            {/*        /!*}}>&nbsp;&nbsp;New&nbsp;&nbsp;</span>*!/*/}
                            {/*    </ListItem>*/}
                            {/*</ProductMarketingMenuItem>*/}


                            {/*<Divider variant="middle" component="li"/>*/}

                            {/*<ProductMarketingMenuItem onClick={popupState.close}>*/}
                            {/*    <ListItem style={{padding: '0px 0px 0px 0px'}} component="a"*/}
                            {/*              href="/products/forecasting">*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <Hidden mdUp><ForecastIcon color="#272931" size="1.65em"/></Hidden>*/}
                            {/*            <ForecastIcon color="#272931" size="1.65em"/>*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary="Sales Forecasting"/>&nbsp;&nbsp;&nbsp;&nbsp;<span*/}
                            {/*        style={{*/}
                            {/*            textDecoration: "none",*/}
                            {/*            whiteSpace: "nowrap",*/}
                            {/*            borderRadius: "3px",*/}
                            {/*            backgroundColor: "#4093D7",*/}
                            {/*            color: "#FFF",*/}
                            {/*            fontWeight: 400,*/}
                            {/*            fontSize: "0.75em",*/}
                            {/*            textTransform: "uppercase"*/}
                            {/*        }}>&nbsp;&nbsp;New&nbsp;&nbsp;</span>*/}
                            {/*    </ListItem>*/}
                            {/*</ProductMarketingMenuItem>*/}
                            {/*<Divider variant="middle" component="li"/>*/}
                            {/*<ProductMarketingMenuItem onClick={popupState.close}>*/}
                            {/*    <ListItem style={{padding: '0px 0px 0px 0px'}} component="a"*/}
                            {/*              href="/products/sales-activity-analytics">*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <Hidden mdUp><MdKeyboardArrowRight color="#272931" size="1.65em"/></Hidden>*/}
                            {/*            <ActivityHeatmapIcon size={"1.65em"} color="#272931"/>*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary="Sales Activity Analytics"/>*/}
                            {/*    </ListItem>*/}
                            {/*</ProductMarketingMenuItem>*/}
                            {/*<Divider variant="middle" component="li"/>*/}
                            {/*<ProductMarketingMenuItem onClick={popupState.close}>*/}
                            {/*    <ListItem style={{padding: '0px 0px 0px 0px'}} component="a"*/}
                            {/*              href="/products/sales-pipeline-analytics">*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <Hidden mdUp><MdKeyboardArrowRight color="#272931" size="1.65em"/></Hidden>*/}
                            {/*            <GiTwoCoins color="#272931" size="1.65em"/>*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary="Sales Pipeline Analytics"/>*/}
                            {/*    </ListItem>*/}
                            {/*</ProductMarketingMenuItem>*/}
                            {/*<Divider variant="middle" component="li"/>*/}


                            {/*<ProductMarketingMenuItem onClick={popupState.close}>*/}
                            {/*    <ListItem style={{padding: '0px 0px 0px 0px'}} component="a"*/}
                            {/*              href="/products/sales-ramp">*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <Hidden mdUp><MdKeyboardArrowRight color="#1de9b6" size="inherit"/></Hidden>*/}
                            {/*            <div*/}
                            {/*                // style={{padding: "0.2em"}}*/}
                            {/*            ><FaRegPaperPlane color="#000" size="1.65em"/></div>*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary="Sales Ramp Management"/>*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;<span*/}
                            {/*        style={{*/}
                            {/*            textDecoration: "none",*/}
                            {/*            whiteSpace: "nowrap",*/}
                            {/*            borderRadius: "3px",*/}
                            {/*            backgroundColor: "#4093D7",*/}
                            {/*            color: "#FFF",*/}
                            {/*            fontWeight: 400,*/}
                            {/*            fontSize: "0.75em",*/}
                            {/*            textTransform: "uppercase"*/}
                            {/*        }}>&nbsp;&nbsp;New&nbsp;&nbsp;</span>*/}
                            {/*    </ListItem>*/}
                            {/*</ProductMarketingMenuItem>*/}
                            {/*<Divider variant="middle" component="li"/>*/}
                            {/*<ProductMarketingMenuItem onClick={popupState.close}>*/}
                            {/*    <ListItem style={{padding: '0px 0px 0px 0px'}} component="a"*/}
                            {/*              href="/products/machine-learning">*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <Hidden mdUp><MdKeyboardArrowRight color="#272931" size="1.65em"/></Hidden>*/}
                            {/*            <GiArtificialHive color="#272931" size="1.65em"/>*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary="Machine Learning Recipes"/>*/}
                            {/*    </ListItem>*/}
                            {/*</ProductMarketingMenuItem>*/}

                            {/*<Divider variant="middle" component="li"/>*/}

                            {/*<ProductMarketingMenuItem onClick={popupState.close}>*/}
                            {/*    <ListItem style={{padding: '0px 0px 0px 0px'}} component="a"*/}
                            {/*              href="/products">*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <Hidden mdUp><MdKeyboardArrowRight color="#272931" size="1.65em"/></Hidden>*/}
                            {/*            <div style={{fontSize: "1.65em"}}><SettingsEthernetIcon fontSize="inherit"*/}
                            {/*                                                                    color="primary"/></div>*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary="Integrations"/>*/}
                            {/*    </ListItem>*/}
                            {/*</ProductMarketingMenuItem>*/}
                            {/*<Hidden mdUp> <ProductMarketingMenuItem onClick={popupState.close}>*/}
                            {/*    <ListItem style={{padding: '0px 0px 0px 0px'}} component="a"*/}
                            {/*              href="/pricing">*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <AiTwotoneTag color="#272931" size="1.65em"/>*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary="Pricing"/>*/}
                            {/*    </ListItem>*/}
                            {/*</ProductMarketingMenuItem>*/}
                            {/*</Hidden>*/}

                            <Hidden mdUp> <ProductMarketingMenuItem onClick={popupState.close}>
                                <ListItem style={{padding: '0px 0px 0px 0px'}} component="a"
                                          href="https://blog.qflow.ai">
                                    <ListItemIcon>
                                        <GoBook color="#272931" size="1.65em"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Blog"/>
                                </ListItem>
                            </ProductMarketingMenuItem>
                            </Hidden>
                        {/*</Card>*/}
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    )
}

export default ProductMarketingMenu