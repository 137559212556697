import React from 'react'
import { PiFlowArrowFill } from "react-icons/pi";
import './IconStyles.css';

export const ClosedWonFlowsIcon = (props) => (
  <div style={{minWidth: "1.75em"}}>
    <PiFlowArrowFill
        color={props.color ? props.color : undefined}
              size={props.size ? props.size : "1.25em"}

    />
   </div>
)
