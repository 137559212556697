import React from 'react'
import './IconStyles.css';
// import {HiPresentationChartLine} from "react-icons/hi";
import { HiOutlinePresentationChartBar } from "react-icons/hi2";

export const BoardStatsIcon = (props) => (
  <div style={{minWidth: "1.75em"}}>
    <HiOutlinePresentationChartBar size="1.25em"
                color={props.color ? props.color : undefined}
    />
  </div>
)