import React from 'react'
import './IconStyles.css';
import {TbConfetti} from "react-icons/tb";

export const BookingTrendsIcon = (props) => (
  <div style={{minWidth: "1.75em"}}>
    <TbConfetti size="1.15em"
                color={props.color ? props.color : undefined}
    />
  </div>
)