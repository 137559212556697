import React from "react";
import { useScrollTrigger } from "@mui/material";

const ScrollHandler = props => {

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: props.window ? window() : undefined
  });

  return React.cloneElement(props.children, {
    // elevation: !props.isAuthenticated && trigger ? 2 : 0,
      style: {
      borderBottom: trigger && !props.isAuthenticated ? "1px solid rgba(0, 0, 0, 0.12)" : undefined,
        background: "#fff",
      color: "#fff",
      transition: trigger ? "0.3s" : "0.5s",
      // boxShadow: trigger ? "1px 1px 0px #272931" : "none",
      // padding: "10px 0px"

    }
  });
}

const ScrollToStyleAppBar = props => {
  return <ScrollHandler {...props}>{props.children}</ScrollHandler>;
};

export default ScrollToStyleAppBar;