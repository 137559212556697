import React from 'react'
import './IconStyles.css';
import {MdOutlineQueryStats} from "react-icons/md";

export const GtmStatsIcon = (props) => (
  <div style={{minWidth: "1.75em"}}>
    <MdOutlineQueryStats size="1.25em"
                color={props.color ? props.color : undefined}
    />
  </div>
)