import { lazy } from 'react';

const sessionKey = 'lazyWithRetry';

const lazyWithRetry = (componentImport) => {
  return lazy(async () => {
    const hasRefreshed = window.sessionStorage.getItem(sessionKey) || 'false';

    try {
      window.sessionStorage.setItem(sessionKey, 'false');
      return await componentImport();
    } catch (error) {
      if (hasRefreshed === 'false') {
        window.sessionStorage.setItem(sessionKey, 'true');
        window.location.reload();
      }

      if (hasRefreshed === 'true') throw new Error('chunkLoadError');

      // Optionally, you can rethrow the original error
      throw error;
    }
  });
};

export default lazyWithRetry;
