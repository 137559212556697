import React from 'react'
import { PiInvoiceDuotone } from "react-icons/pi";
import './IconStyles.css';

export const CustomerInvoicesIcon = (props) => (
    <div style={{minWidth: "1.75em"}}>
    <PiInvoiceDuotone
        color={props.color ? props.color : undefined}
                   size={props.size ? props.size : "1.25em"}
    />
    </div>
)