import {createTheme} from '@mui/material/styles';

const customPalettes = {secondaryNew: {
            main: "#4DA685",
            contrastText: '#FFFFFFFF',
        },
        tertiary: {
            main: '#2563eb',
            contrastText: '#FFFFFFFF',
        },
        quaternary:{
            main: '#b61de9',
            contrastText: '#FFFFFFFF',
        },}


const theme = createTheme({
    palette:
{
        type: 'light',

        // warning: {
        //     main: "#fccb29",
        //     light: "#ffe66d",
        //     dark: "#f69b1f",
        //     contrastText: "#fff"
        // },
        primary: {
            main: "#272931",
            contrastText: '#fff',
        },

        secondary: {
            // main: '#1de9b6',
            main: '#4DA685',
            contrastText: '#FFFFFFFF',
        },

        ...customPalettes,
        // secondaryNew: {
        //     main: "#10a983",
        //     contrastText: '#FFFFFFFF',
        // },
        // tertiary: {
        //     main: '#2563eb',
        //     contrastText: '#FFFFFFFF',
        // },
        // quaternary:{
        //     main: '#b61de9',
        //     contrastText: '#FFFFFFFF',
        // },

        background: {
            default: "#FFFFFFFF",
            paper: "#FFFFFFFF",
        }
    },
    components: {
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    contrastText: "#000"
                }
            }
        },
        MuiListSubheader: {
            styleOverrides: {
                tooltip: {
                    fontFamily: "Assistant, sans serif",
                    // fontSize: "1.35em"
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontFamily: "Assistant, sans serif",
                    // fontSize: "1.35em"
                }
            }
        },
        MuiPickersMonth: {
            styleOverrides: {
                monthButton: {
                    fontSize: 18, textAlign: "center",
                    color: "rgb(39,41,49)",
                    "& .Mui-Selected": {color: "#fff"}
                }
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    fontFamily: "Assistant, sans serif",
                    fontWeight: 800
                }
            }
        },
        MuiPickersYear: {
            styleOverrides: {
                yearButton: {
                    fontSize: 18, textAlign: "center",
                    color: "rgb(39,41,49)",
                    "& .Mui-Selected": {color: "#fff"}
                }
            },
        },
        MuiBox: {
            defaultProps: {
                // backgroundColor: "#fbbf24",
                backgroundColor: "#fff",
                fontFamily: 'Assistant, sans-serif;',
            },
        },
        MUIDataTable: {
            "& .MuiToolbar-root": {
                fontFamily: "Assistant, sans serif",
            },
            "& .MuiTypography-root": {
                fontFamily: "Assistant, sans serif",
            }
        },
        MUIDataTableHeadCell: {
            styleOverrides: {
                root: {
                    fontWeight: 700,
                    "& .MuiButtonBase-root": {
                        fontWeight: 700,
                    }
                }
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontFamily: "Assistant, sans serif",
                }
            }
        },
        MuiScopedCssBaseline: {
            styleOverrides: {
                root: {
                            backgroundColor: "#272931",
                            // backgroundImage: `url(${HomepageHeroSVG})`,
                            // backgroundRepeat: 'repeat-x',
                            // backgroundPosition: "-400px -180px, 50% 100%",
                            // backgroundSize: "cover",
                            // // backgroundSize: "cover",
                            // backgroundAttachment: "scroll",
                }
            },
            defaultProps: {
                fontFamily: 'Assistant, sans-serif;',
                backgroundColor: "#272931",
                // '-apple-system',
                // 'BlinkMacSystemFont',
                // '"Segoe UI"',
                // 'Helvetica Neue',
                // 'Arial',
                // 'sans-serif',
                // '"Apple Color Emoji"',
                // '"Segoe UI Emoji"',
                // '"Segoe UI Symbol"',
            },
        },
        MuiPaper: {
            defaultProps: {
                fontFamily: [
                    'Assistant, sans-serif',
                    '-apple-system',
                    'BlinkMacSystemFont',
                    '"Segoe UI"',
                    'Helvetica Neue',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"',
                ].join(','),
            }
        }
    },
    typography: {
        fontSize: 14,
        allVariants: {
            fontFamily: [
                'Assistant, sans-serif',
                // '-apple-system',
                // 'BlinkMacSystemFont',
                // '"Segoe UI"',
                // 'Helvetica Neue',
                // 'Arial',
                // 'sans-serif',
                // '"Apple Color Emoji"',
                // '"Segoe UI Emoji"',
                // '"Segoe UI Symbol"',
            ].join(',')
        },
        body1: {
            fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
        },
        body2: {
            fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
        },
        h1: {
            fontSize: '3.5em',
            fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
            webkitFontSmoothing: 'antialiased',
            textRendering: "optimizeLegibility",
            fontWeight: 'semi-bold',
            textAlign: 'left',
        },
        h2: {
            fontSize: '1.8em',
            lineHeight: '1.25em',
            webkitFontSmoothing: 'antialiased',
            textRendering: "optimizeLegibility",
            color: '#272931',
            fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
            fontWeight: 'bold',
            textAlign: 'left',
        },
        h3: {
            fontSize: '1em',
            lineHeight: '1.1em',
            webkitFontSmoothing: 'antialiased',
            textRendering: "optimizeLegibility",
            fontFamily: 'Assistant, sans-serif; pen sans, sans-serif',
            fontWeight: 'semi-bold',
            textAlign: 'left',
        },
        h4: {
            fontSize: '1.2em',
            lineHeight: '1.2em',
            webkitFontSmoothing: 'antialiased',
            textRendering: "optimizeLegibility",
            color: '#272931',
            fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
            fontWeight: 'normal',
            textAlign: 'left',
        },
        h5: {
            fontSize: '1.5em',
            lineHeight: '1.5em',
            webkitFontSmoothing: 'subpixel-antialiased !important',
            mozOsxFontSmoothing: 'grayscale',
            textRendering: "optimizeLegibility",
            color: '#272931',
            fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
            fontWeight: 'bold',
            textAlign: 'left',
        },

        h6: {
            fontSize: '1.1em',
            lineHeight: '1.1em',
            webkitFontSmoothing: 'antialiased',
            textRendering: "optimizeLegibility",
            mozOsxFontSmoothing: 'grayscale',
            color: '#272931',
            textTransform: 'uppercase',
            fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
            fontWeight: 'normal',
            textAlign: 'left',
        },

        subtitle1: {
            fontSize: '1.5em',
            lineHeight: '1.1em',
            webkitFontSmoothing: 'antialiased',
            textRendering: "optimizeLegibility",
            mozOsxFontSmoothing: 'grayscale',
            color: '#000',
            fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
            fontWeight: 'normal',
            textAlign: 'left',
        },
        subtitle2: {
            fontSize: '1.5em',
            // lineHeight: '1.1em',
            webkitFontSmoothing: 'subpixel-antialiased',
            mozOsxFontSmoothing: 'grayscale',
            textRendering: "optimizeLegibility",
            fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
            fontWeight: 'normal',
            textAlign: 'left',
        },
        fontWeightBold: 700
    },

});

export default theme;

// const oldtheme = createTheme({
//     components: {
//         MuiScopedCssBaseline: {
//             defaultProps: {
//                 fontFamily: "Assistant",
//                 sx: {
//                     background: "#fff",
//                     backgroundColor: "#fff",
//                     // lineHeight: 1.43,
//                     // letterSpacing: '0.01071em',
//                 },
//             },
//         },
//         MuiPaper: {
//             defaultProps: {
//                 elevation: 0,
//             }
//         }
//     },
//     breakpoints: {
//         values: {
//             xs: 0,
//             sm: 500,
//             md: 860,
//             lg: 1220,
//             xl: 1620,
//         }
//     },
//     MuiContainer: {
//         paddingLeft: "5em",
//     },
//     // paletteRo
//     yellow: "#ffe66d",
//     // yellow: "#eabc02",
//     green: "#1de9b6",
//     red: "#ff6b6b",
//     orangeMuted: '#EF8533',
//
//     ltOrange: '#F4C17C',
//     mdOrange: '#EF8533',
//     dkOrange: '#EF8533',
//     vlightblue: "rgb(232, 244, 253)",
//     blue: "#9fd7f3",
//     ltblue: "#73c6ff",
//     dkblue: "#579FEE",
//     dkbluetext: "#0d4684",
//     white: "#f7fff7",
//     // background: "#272931",
//     contrast: "#646464",
//
//     // #14171B < dark background
//
//     background: {
//         paper: "#fff",
//         default: "#fff"
//     },
//     palette: {
//         type: 'light',
//         background: {
//             default: "#fff",
//             paper: "#fff",
//         },
//         common: {
//             blue: "#579FEE",
//             green: "#1de9b6"
//         },
//         primary: {
//             light: '#4f515b',
//             main: '#fff',
//             dark: '#00000a',
//             contrastText: '#f7fff7',
//         },
//         secondary: {
//             light: '#ffffff',
//             // main: '#EB9047',
//             monoDark: '#10a983',
//             monoPurple: '#b61de9',
//             main: '#1de9b6',
//             dark: '#34db9d',
//             contrastText: '#000',
//         },
//         text: {
//             primary: "#272931",
//             tertiary: "#579FEE",
//             // secondary: "#579FEE"
//         }
//     },
//     type: 'dark',
//     shape: {
//         borderRadius: 3,
//     },
//     divider: {
//         light: {
//             backgroundColor: "#fff"
//         }
//     },
//     typography: {
//         allVariants: {
//             fontFamily: [
//                 'Assistant, sans-serif',
//                 '-apple-system',
//                 'BlinkMacSystemFont',
//                 '"Segoe UI"',
//                 'Helvetica Neue',
//                 'Arial',
//                 'sans-serif',
//                 '"Apple Color Emoji"',
//                 '"Segoe UI Emoji"',
//                 '"Segoe UI Symbol"',
//             ].join(',')
//         },
//         body1: {
//             fontSize: '1em',
//             fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
//             webkitFontSmoothing: 'antialiased',
//             textRendering: "optimizeLegibility"
//         },
//         body2: {
//             fontSize: '1em',
//             fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
//             webkitFontSmoothing: 'antialiased',
//             textRendering: "optimizeLegibility"
//         },
//         h1: {
//             fontSize: '3em',
//             color: '#1de9b6',
//             fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
//             webkitFontSmoothing: 'antialiased',
//             textRendering: "optimizeLegibility",
//             fontWeight: 'semi-bold',
//             textAlign: 'left',
//         },
//         h2: {
//             fontSize: '1.8em',
//             lineHeight: '1.25em',
//             webkitFontSmoothing: 'antialiased',
//             textRendering: "optimizeLegibility",
//             color: '#272931',
//             fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
//             fontWeight: 'bold',
//             textAlign: 'left',
//         },
//         h3: {
//             fontSize: '1.2em',
//             lineHeight: '1.25em',
//             webkitFontSmoothing: 'antialiased',
//             textRendering: "optimizeLegibility",
//             color: '#1de9b6',
//             fontFamily: 'Assistant, sans-serif; pen sans, sans-serif',
//             fontWeight: 'semi-bold',
//             textAlign: 'left',
//         },
//         h4: {
//             fontSize: '1.2em',
//             lineHeight: '1.2em',
//             webkitFontSmoothing: 'antialiased',
//             textRendering: "optimizeLegibility",
//             color: '#272931',
//             fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
//             fontWeight: 'normal',
//             textAlign: 'left',
//         },
//         h5: {
//             fontSize: '1.5em',
//             lineHeight: '1.5em',
//             webkitFontSmoothing: 'subpixel-antialiased !important',
//             mozOsxFontSmoothing: 'grayscale',
//             textRendering: "optimizeLegibility",
//             color: '#272931',
//             fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
//             fontWeight: 'bold',
//             textAlign: 'left',
//         },
//
//         h6: {
//             fontSize: '1.1em',
//             lineHeight: '1.1em',
//             webkitFontSmoothing: 'antialiased',
//             textRendering: "optimizeLegibility",
//             mozOsxFontSmoothing: 'grayscale',
//             color: '#272931',
//             textTransform: 'uppercase',
//             fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
//             fontWeight: 'normal',
//             textAlign: 'left',
//         },
//
//         subtitle1: {
//             fontSize: '1.5em',
//             lineHeight: '1.1em',
//             webkitFontSmoothing: 'antialiased',
//             textRendering: "optimizeLegibility",
//             mozOsxFontSmoothing: 'grayscale',
//             color: '#28CDD7',
//             fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
//             fontWeight: 'normal',
//             textAlign: 'left',
//         },
//         subtitle2: {
//             fontSize: '1.5em',
//             lineHeight: '1.1em',
//             webkitFontSmoothing: 'subpixel-antialiased',
//             mozOsxFontSmoothing: 'grayscale',
//             textRendering: "optimizeLegibility",
//             color: '#fff',
//             fontFamily: 'Assistant, sans-serif; Open sans, sans-serif',
//             fontWeight: 'normal',
//             textAlign: 'left',
//         },
//         fontWeightBold: 700
//     },
// })
// console.log(theme);
