import React from 'react'
import './IconStyles.css';
import { LuText } from "react-icons/lu";

export const DigestIcon = (props) => (
    <div style={{minWidth: "1.75em"}}>
        <LuText
            color={props.color ? props.color : undefined}
            size={props.size ? props.size : "1.15em"}
        />
    </div>
)