import React from 'react'
import './IconStyles.css';
import {RiDashboardLine} from "react-icons/ri";

export const DashboardIcon = (props) => (
    <div style={{minWidth: "1.75em"}}>
    <RiDashboardLine
        color={props.color ? props.color : undefined}
                   size={props.size ? props.size : "1.15em"}
    />
    </div>
)