import React from 'react'
import { FiChevronsRight } from "react-icons/fi";

import './IconStyles.css';

export const PConvertIcon = (props) => (
    <div style={{minWidth: "1.75em"}}>
    <FiChevronsRight  size={props.size ? props.size : "1.25em"}
                         color={props.color ? props.color : undefined}
                        // className="globaliconstyles"
    />
    </div>
)