import React from 'react'
import './IconStyles.css';
import { PiCrown } from "react-icons/pi";


export const OpportunityCreationIcon = (props) => (
     <div style={{minWidth: "1.75em"}}>
		<PiCrown size={props.size ? props.size : "1.15em"}
    color={props.color ? props.color : undefined}/>
    </div>
)