
    import { IoMedalOutline } from "react-icons/io5";
import React from 'react'
import './IconStyles.css';

export const PCloseIcon = (props) => (
    <div style={{minWidth: "1.75em"}}>


        <IoMedalOutline size={props.size ? props.size : "1.2em"}
             color={props.color ? props.color : undefined}
    />
    </div>
)