import React from 'react'
import './IconStyles.css';
import { PiCoin } from "react-icons/pi";

export const PipelineAnalyticsIcon = (props) => (
  <div
      style={{minWidth: "1.75em"}}
  >
    <PiCoin size="1.25em"
                color={props.color ? props.color : undefined}
                // className="globaliconstyles"
    />
   </div>
)