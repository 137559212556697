import React from 'react'
import './IconStyles.css';
import {GoTelescope} from "react-icons/go";

export const ForecastIcon = (props) => (
  <div style={{minWidth: "1.75em"}}>
    <GoTelescope size="1.25em"
                color={props.color ? props.color : undefined}
                // className="globaliconstyles"
    />
  </div>
)