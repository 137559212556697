import React from 'react'
import { MdOutlineCampaign } from "react-icons/md";
import './IconStyles.css';

export const ConfigIcon = (props) => (
   <div style={{minWidth: "1.75em"}}>
    <MdOutlineCampaign size={props.size ? props.size : "1.25em"}
                       color={props.color ? props.color : undefined}
    />
   </div>
)