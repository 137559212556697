

import { Helmet } from 'react-helmet-async';
import {config} from "../../config";
import React from "react";

export default function HomepageHelmet() {

    const jsonToSet = {
        "@context": "https://schema.org",
        "@type": "WebApplication",
        "name": "QFlow.ai",
        "maintainer": {
            "@type": "Organization",
            "Name": "QFlow.ai"
        },
        "creator": {
            "@type": "Organization",
            "Name": "QFlow.ai"
        },
        "url": "https://qflow.ai",
        "inLanguage": "en-US",
        "about": {
            "@type": "Thing",
            "description": "Revenue Modeling Platform, Multi-Touch Attribution, Campaign Measurement, Sales Productivity Analytics, Sales Activity Analytics, Machine Learning Recipes"
        },
        "keywords": "sales forecasting, machine learning for marketing, machine learning for sales, marketing attribution, sales attribution, Salesforce.com, B2B",
        "audience": {
            "@type": "Audience",
            "name": "B2B businesses"
        },
        "installUrl": "https://qflow.ai/signup",
        "operatingSystem": "All",
        "browserRequirements": "Requires JavaScript. Requires HTML5.",
        "applicationCategory": "BusinessApplication"
    }

    const orgJsonToSet = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "QFlow.ai",
        "url": "https://qflow.ai",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "",
            "addressLocality": "San Francisco",
            "addressRegion": "CA",
            "postalCode": "94107",
            "addressCountry": "USA"
        },
        "foundingDate": "2021",
        "founders": [
            {
                "@type": "Person",
                "name": "Alex Brower",
                "title": "Co-Founder",
                "alumniOf": "College of Wooster",
                "sameAs": "https://www.linkedin.com/in/alexbrower/",
                "gender": "Male",
                "Description": "Technologist & Operator",
                "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "San Francisco",
                    "addressRegion": "CA",
                    "addressCountry": "USA"
                },
                "image": "https://qflow.ai/qflow-logo.png"
            },
            {
                "@type": "Person",
                "name": "Vlad Kolpakov",
                "title": "Co-Founder",
                "alumniOf": "Yale",

                "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "San Francisco",
                    "addressRegion": "CA",
                    "addressCountry": "USA"
                }
            }],
        "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "customer support",
            "email": "appadmin@qflow.ai"
        },
        "logo": "https://qflow.ai/qflow-logo.png",
        "sameAs": ["https://www.crunchbase.com/organization/qflow-ai", "https://www.facebook.com/qflowai", "https://www.linkedin.com/company/qflow-ai", "https://twitter.com/QFlowAI"]
    }

    return (
        <div>
            <Helmet>
                <title>QFlow.ai | GTM Finance Made Easy</title>
                <meta charSet="utf-8"/>

                <link rel="alternate" hrefLang="en-us" href="https://qflow.ai"/>
                <link rel="alternate" hrefLang="x-default" href="https://qflow.ai"/>

                <script type="application/ld+json">{`
                ${JSON.stringify(jsonToSet)}
                `}</script>

                <script type="application/ld+json">{`
                            ${JSON.stringify(orgJsonToSet)}
                `}</script>
                <meta name="description"
                      content="QFlow equips every B2B company with the power of a robust GTM Finance team. In minutes."/>
                <meta name="referrer" content="same-origin"/>
                <link rel="apple-touch-icon" href="/logo192.png"/>
                <meta name="author" content="QFlow.ai"/>
                <meta name="theme-color" content="#ffffff"/>
                <meta content="text/html; charset=UTF-8" name="Content-Type"/>
                <meta name="twitter:site" content="@qflowai"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:image"
                      content={`${config.REACT_APP_FRONTEND_HOST}/images/qflow-social.png`}/>
                <meta name="twitter:image:alt" content="QFlow.ai"/>
                <meta name="twitter:title" content="QFlow.ai | GTM Finance Made Easy"/>
                <meta name="twitter:description"
                      content="QFlow equips every B2B company with the power of a robust GTM Finance team. In minutes."/>
                {/*<meta name="twitter:label1" content="Included in"/>*/}
                {/*<meta name="twitter:data1" content="Standard Plan, Pro Plan 🦾"/>*/}
                <meta name="twitter:label1" content="Total Configuration Time"/>
                <meta name="twitter:data1" content="Under 30 minutes ⏱"/>
                <meta property="og:site_name" content="QFlow.ai"/>
                <meta property="og:url" content="https://qflow.ai"/>
                <meta property="og:description"
                      content="QFlow equips every B2B company with the power of a robust GTM Finance team. In minutes."/>
                <meta property="og:image" content={`${config.REACT_APP_FRONTEND_HOST}/images/qflow-social.png`}/>
                <meta property="og:image:alt"
                      content="QFlow equips every B2B company with the power of a robust GTM Finance team. In minutes."/>
                <meta property="og:site_name" content="QFlow.ai"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="QFlow.ai | GTM Finance Made Easy"/>
                <link rel="canonical" href="https://qflow.ai"/>
            </Helmet>

        </div>
    )
}