import React from 'react'
import './IconStyles.css';
import { MdOutlineRampRight } from "react-icons/md";

export const SalesRampIcon = (props) => (
  <div style={{minWidth: "1.75em"}}>
    <MdOutlineRampRight size="1.25em"
                     color={props.color ? props.color : undefined}
    />
  </div>
)