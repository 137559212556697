import React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import {useHistory} from "react-router";
import Box from "@mui/material/Box";


export default function NotSetupSnackBar(props) {

    const history = useHistory();

    return (

        <Box sx={{maxWidth: 600}}>

            <Snackbar
                open={props.snackbarState.open}
                autoHideDuration={15000}
                onClose={props.handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}

                // TransitionComponent={Grow}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{props.message}</span>}
                action={[
                    <Button key='contactUsButton' color="secondary"
                            data-cal-link="team/qflow/30-min-call" data-cal-config='{"layout":"month_view"}' data-cal-namespace=""
                    >I need help</Button>,
                    <Button
                        key="close"
                        aria-label="close"
                        color="inherit"
                        onClick={props.handleClose}
                    >
                        Understood
                    </Button>,
                ]}
            />
        </Box>

    )

}