import React from 'react'
import { PiPipe } from "react-icons/pi";
import './IconStyles.css';

export const PipelineFlowsIcon = (props) => (
   <div style={{minWidth: "1.75em"}}>
    <PiPipe  size={props.size ?  props.size : "1.25em"}
                 color={props.color ? props.color : undefined}
                 // className="globaliconstyles"
    />
   </div>
)