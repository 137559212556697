import React from 'react'
import { TbMoneybag } from "react-icons/tb";
import './IconStyles.css';

export const DepartmentSpendIcon = (props) => (
    <div style={{minWidth: "1.75em"}}>
    <TbMoneybag
        color={props.color ? props.color : undefined}
                   size={props.size ? props.size : "1.25em"}
        // className="globaliconstyles"
    />
    </div>
)