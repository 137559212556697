import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import Typography from "@mui/material/Typography";
// import { logError } from "../libs/errorLib";




// function FallbackComponent(props) {
//   return (<Alert severity='warning'><AlertTitle>“Expect everything, I always say, and the unexpected never happens.”</AlertTitle>
//       Something very unexpected happened. The error has been logged, but if you're in urgent need of support, please contact appadmin [at] qflow.ai.
//   {props.error}
//   </Alert>
//   )
// }

export default function ErrorFallback({error}) {
  return (
    <Alert severity='warning'><AlertTitle>“Expect everything, I always say, and the unexpected never happens.”</AlertTitle>
        Something very unexpected happened. The error has been logged. <b>Please try refreshing the page and if that does not resolve things, contact support [at] qflow.ai.</b>
  <br/><Typography variant='caption'> {error.message}</Typography>
  </Alert>
  )
}

//
//
// class ErrorBoundary extends React.Component {
//   state = {
//     error: null,
//   };
//
//   static getDerivedStateFromError(error) {
//     // Update state so next render shows fallback UI.
//     return { error: error };
//   }
//
//   componentDidCatch(error, info) {
//     // Log the error to an error reporting service
//     // console.log(error, info)
//     logError(error, info);
//   }
//
//   render() {
//     return(
//      <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
//        {this.props.children}
//       </Sentry.ErrorBoundary>
//         )
//
//   //   if (this.state.error) {
//   //     // You can render any custom fallback UI
//   //     return <Alert severity='warning'><AlertTitle>“Expect everything, I always say, and the unexpected never happens.”</AlertTitle>
//   //     Something very unexpected happened. The error has been logged, but if you're in urgent need of support, please contact appadmin [at] qflow.ai</Alert>;
//   //   }
//   //   return this.props.children;
//   }
// }