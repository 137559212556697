import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';
import Button from "@mui/material/Button";
import {MdClose} from "react-icons/md";
import {useAppContext} from "../../libs/appContextLib";
import fromUnixTime from "date-fns/fromUnixTime";

import {get} from "lodash";

export default function TrialReminderSnackbar(props) {


    const {subscriptionStatus} = useAppContext();


    
    
    
    const subscriptionName = get(subscriptionStatus, 'stripe_subscription.stripe_plan_nickname', undefined)
    const currentTrialEnd = get(subscriptionStatus, 'stripe_subscription.stripe_trial_end', undefined)
    // const cleanedCurrentPlanNickname = currentPlanNickname && currentPlanNickname.split("-")[0].slice(0, 1) + currentPlanNickname.split("-")[0].slice(1).toLowerCase()
 const cleanSubscriptionName = () => {
        try {
            if(subscriptionName && subscriptionName !== "none"){
            return subscriptionName.indexOf("QF-BUSINESS") > -1 ?
                "business"
                : subscriptionStatus.indexOf("QF-SALES") > -1 ?
                    "sales"
                    : subscriptionStatus.indexOf("QF-MARKETING") > -1 ?
                        "marketing"
                        : subscriptionStatus.indexOf("STANDARD") > -1 ?
                            "business"
                            : "sales"
                } else return "business"
        } catch (error) {
            console.log("subscription error", error)
            return  "business"
        }
    }
    return (
        <Snackbar
            open={props.alertOpen}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            // TransitionComponent={SlideTransition}
        >
            <Alert
                onClose={() => {
                }}
                severity="info"
                action={
                    <Button color="inherit" size="small"
                            onClick={(e) => props.handleTrialAlertClose(e)}
                    >
                        <MdClose size="1.5em"/>
                    </Button>
                }
            >
                {/*<AlertTitle>Trial</AlertTitle>*/}
                Your trial of {cleanSubscriptionName() ? 'the QFlow.ai ' + cleanSubscriptionName() + ' plan' : 'QFlow.ai'} ends
                &nbsp;
                {fromUnixTime(parseInt(currentTrialEnd)).toDateString()}
            </Alert>


        </Snackbar>
    )


}
