import React from 'react'
import './IconStyles.css';
import {MdOutlineEventSeat} from "react-icons/md";

export const ActivityHeatmapIcon = (props) => (
    <div style={{minWidth: "1.75em"}}>
    <MdOutlineEventSeat size={props.size ? props.size : "1.25em"}
    color={props.color ? props.color : undefined}
    />
    </div>
)