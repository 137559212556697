// apiClient.js
import axios from 'axios';
import { Auth } from 'aws-amplify';

const apiClient = axios.create();

// Add a request interceptor
apiClient.interceptors.request.use(
  async (config) => {
    // Retrieve the token locally; no network request unless token is expired
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    config.headers['Authorization'] = token;
    return config;
  },
  (error) => Promise.reject(error)
);

export default apiClient;